import React from 'react'
const stats = [
    { id: 1, name: 'Estabished', value: '2024' },
    { id: 2, name: 'Registered User', value: '50,000,000 +' },
    { id: 3, name: 'Product', value: '46,000' },
  ]
const TrustedBroker = () => {
  return (
   <>
   
  <section className="bg-black lg:my-20 sm:my-16 my-8  ">
  <div className="max-w-7xl mx-auto px-5">
    <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
    <div className="mt-12 md:mt-0  bg-gradient-to-r from-[#000203] to-blue-500  relative p-[2px] rounded-md">
        <img src="trading.png" alt="About Us Image" className="object-cover rounded-lg shadow-md" />
      </div>
      <div>
        <h2 className="font-semibold text-[#42d1f5] uppercase text-2xl md:text-3xl">Your Intelligent Trading Companion</h2>
        <p className="mt-4 text-gray-200 text-base text-justify">Bot-Powered Solutions
        BlackBot gives you easy-to-use trading tools powered by bots to help you take control of your financial future. Our Forex trading robots and smart systems are made to help you increase profits and reduce risks in the fast-moving forex market.</p>
          <p className="mt-4 text-gray-200 text-base text-justify">With BlackBot, you can use the power of AI and machine learning. Our smart systems analyze market trends, find good opportunities, and make trades quickly and accurately. Whether you're experienced or new to trading, BlackBot's smart trading bots will help you reach your financial goals with confidence.</p>
          <div className='mt-5'> 
          <div class="relative z-10 flex w-full cursor-pointer items-center overflow-hidden rounded-xl border border-slate-800 p-[1.5px]">
          <div class="animate-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#0ea5e9_20deg,transparent_120deg)]"></div>
          <div class="relative z-20 flex w-full justify-between rounded-[0.60rem] bg-slate-900 p-2">       
          <dl className="grid grid-cols-1 w-full px-3 gap-x-8 gap-y-16 justify-between lg:grid-cols-3">
          {stats.map((stat) => (
            <div key={stat.id} className="">
              <dd className=" text-2xl font-semibold tracking-tight text-white">{stat.value}</dd>
              <dt className="text-base  text-gray-200">{stat.name}</dt>
            </div>
          ))}
         
        </dl>
        </div>
        </div>
          </div>
        <div className="mt-12">
          <a href="#" className=" uppercase hover:text-gray-900 font-medium bg-[#42d1f5] hover:bg-[#1ab3d9] text-sm px-4 rounded-full text-black py-2">Find out more</a>
        </div>
      </div>
      
    </div>
  </div>
</section>

   </>
  )
}

export default TrustedBroker