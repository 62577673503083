// import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import Spinner from "../comman/Spinner";
// import { signupUser, clearErrors, clearMessage } from "../../redux/authSlice";
// import ErrorAlert from "../comman/ErrorAlert";
// import SuccessAlert from "../comman/SuccessAlert";
// import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };
// export default function Registration() {
//   const [referralCode, setReferralCode] = useState(null);
//   const query = useQuery();

//   useEffect(() => {
//     const referral = query.get("referral");
//     console.log(referral);
//     if (referral) {
//       setReferralCode(referral);
//     }
//   }, [query]);

//   const [showPass, setShowPass] = useState(false);
//   const { loading, error, message, auth } = useSelector((state) => state.auth);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const initialValues = {
//     username: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//     referralBy: "",
//   };
//   const validationSchema = Yup.object().shape({
//     email: Yup.string().email("Incorrect email").required("Email is required"),
//     password: Yup.string().required("Password is required"),
//     confirmPassword: Yup.string().required("confirm password is required"),
//     username: Yup.string().required("username is required"),
//   });

//   const formik = useFormik({
//     initialValues,
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {
//       if (referralCode) {
//         values.referralBy = referralCode;
//       }
//       dispatch(signupUser(values));
//     },
//   });

//   useEffect(() => {
//     if (error) {
//       const timer = setTimeout(() => {
//         dispatch(clearErrors());
//       }, 2000);

//       return () => clearTimeout(timer);
//     }
//     if (message) {
//       const timer = setTimeout(() => {
//         navigate("/");
//         dispatch(clearMessage());
//       }, 1000);

//       return () => clearTimeout(timer);
//     }
//   }, [error, dispatch, message, formik]);

//   return (
//     <>
//       <>
      
//         <div className="flex min-h-full flex-1 bg-black">
//           <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
//             <div className="mx-auto w-full max-w-sm lg:w-96">
//               <div>
//                 <img
//                   className="mx-auto h-10 w-auto"
//                   src="/Blackbot.png"
//                   alt="Your Company"
//                 />
//                 <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-300">
//                   Sign Up Here
//                 </h2>
//               </div>

//               <div className="mt-10">
//                 <div>
//                   <form className="space-y-2 " onSubmit={formik.handleSubmit}>
//                     <div className="md:flex gap-x-4 justify-between ">
//                       <div className="w-full">
//                         <label
//                           htmlFor="username"
//                           className="block text-sm font-medium leading-6 text-gray-300"
//                         >
//                           Username
//                         </label>
//                         <div className="mt-2">
//                           <input
//                             id="username"
//                             name="username"
//                             value={formik.values.username}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             type="text"
//                             required
//                             className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
//                           />
//                         </div>
//                         {formik.touched.username && formik.errors.username && (
//                           <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
//                             {formik.errors.username}*
//                           </p>
//                         )}
//                       </div>
//                       <div className="w-full">
//                         <label
//                           htmlFor="email"
//                           className="block text-sm font-medium leading-6 text-gray-300"
//                         >
//                           email
//                         </label>
//                         <div className="mt-2">
//                           <input
//                             id="email"
//                             name="email"
//                             value={formik.values.email}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             type="email"
//                             required
//                             className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
//                           />
//                         </div>
//                         {formik.touched.email && formik.errors.email && (
//                           <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
//                             {formik.errors.email}*
//                           </p>
//                         )}
//                       </div>
//                     </div>
//                     <div className="md:flex gap-x-4 justify-between ">
//                       <div className="w-full">
//                         <label
//                           htmlFor="password"
//                           className="block text-sm font-medium leading-6 text-gray-300"
//                         >
//                           Password
//                         </label>
//                         <div className="mt-2">
//                           <input
//                             id="password"
//                             name="password"
//                             value={formik.values.password}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             type="password"
//                             required
//                             className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
//                           />
//                         </div>
//                         {formik.touched.password && formik.errors.password && (
//                           <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
//                             {formik.errors.password}*
//                           </p>
//                         )}
//                       </div>
//                       <div className="w-full">
//                         <div className="flex items-center justify-between">
//                           <label
//                             htmlFor="confirmPassword"
//                             className="block text-sm font-medium leading-6 text-gray-300"
//                           >
//                             confirm Password
//                           </label>
//                         </div>
//                         <div className="mt-2">
//                           <input
//                             id="confirmPassword"
//                             name="confirmPassword"
//                             value={formik.values.confirmPassword}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             type={showPass ? "text" : "password"}
//                             autoComplete="current-password"
//                             required
//                             className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
//                           />
//                           <span
//                             onClick={() => setShowPass(!showPass)}
//                             className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-600 cursor-pointer"
//                           >
//                             {/* {!showPass ? (
//                         <FaRegEye
//                           className="h-6 w-6 text-gray-600"
//                           aria-hidden="true"
//                         />
//                       ) : (
//                         <FaRegEyeSlash
//                           className="h-6 w-6 text-gray-600"
//                           aria-hidden="true"
//                         />
//                       )} */}
//                           </span>
//                         </div>
//                         {formik.touched.confirmPassword &&
//                           formik.errors.confirmPassword && (
//                             <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
//                               {formik.errors.confirmPassword}*
//                             </p>
//                           )}
//                       </div>
//                     </div>

//                     <div>
//                       <label
//                         htmlFor="referralBy"
//                         className="block text-sm font-medium leading-6 text-gray-300"
//                       >
//                         Referral Code (Optional)
//                       </label>
//                       <div className="mt-2">
//                         <input
//                           id="referralBy"
//                           name="referralBy"
//                           value={formik.values.referralBy}
//                           onChange={formik.handleChange}
//                           onBlur={formik.handleBlur}
//                           type="text"
//                           className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
//                         />
//                       </div>
//                       {formik.touched.referralBy &&
//                         formik.errors.referralBy && (
//                           <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
//                             {formik.errors.referralBy}*
//                           </p>
//                         )}
//                     </div>

//                     {error && <ErrorAlert error={error} />}
//                     {message && <SuccessAlert error={message} />}
//                     <div>
//                       <button
//                         type="submit"
//                         className={`flex w-full uppercase tracking-widest justify-center rounded mt-5 ${
//                           loading
//                             ? "bg-gray-200"
//                             : "bg-gray-800 hover:bg-indigo-600"
//                         } px-3 py-1.5 px-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
//                       >
//                         {loading ? <Spinner /> : "Registration"}
//                       </button>
//                     </div>
//                   </form>
//                   <p className="mt-4 text-center text-gray-300 text-sm ">
//                     Already a member?{" "}
//                     <Link
//                       to="/user/Login"
//                       className="font-semibold leading-6 text-gray-300"
//                     >
//                       Login Here
//                     </Link>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="relative hidden w-0 flex-1 lg:block">
//             <img
//               alt=""
//               src="./bot.png"
//               className="absolute inset-0 h-full w-full object-cover"
//             />
//           </div>
//         </div>
//       </>
//     </>
//   );
// }


import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../comman/Spinner";
import { signupUser, clearErrors, clearMessage } from "../../redux/authSlice";
import ErrorAlert from "../comman/ErrorAlert";
import SuccessAlert from "../comman/SuccessAlert";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Registration() {
  const [referralCode, setReferralCode] = useState(null);
  const query = useQuery();

  useEffect(() => {
    const referral = query.get("referral");
    if (referral) {
      setReferralCode(referral);
    }
  }, [query]);

  const [showPass, setShowPass] = useState(false);
  // const [showoff, setShowOff] = useState(false);

  const { loading, error, message, auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    referralBy: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Incorrect email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required("Confirm password is required"),
    username: Yup.string().required("Username is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (referralCode) {
        values.referralBy = referralCode;
      }
      dispatch(signupUser(values));
    },
  });

useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearErrors());
      }, 2000);

      return () => clearTimeout(timer);
    }
    if (message) {
      const timer = setTimeout(() => {
        navigate("/");
        dispatch(clearMessage());
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [error, dispatch, message, formik]);

  return (
    <>
      <div className="flex min-h-full flex-1 bg-black">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
               <Link
                to="/">
              <img
                className="mx-auto h-10 w-auto text-white cursor-pointer"
                src="/logo.png"
                alt="Your Company"
              /></Link>
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-300">
                Sign Up Here
              </h2>
            </div>

            <div className="mt-10">
              <div>
                <form className="space-y-2" onSubmit={formik.handleSubmit}>
                  <div className="md:flex gap-x-4 justify-between">
                    <div className="w-full">
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium leading-6 text-gray-300"
                      >
                        Username
                      </label>
                      <div className="mt-2">
                        <input
                          id="username"
                          name="username"
                          value={formik.values.username}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type="text"
                          required
                          className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {formik.touched.username && formik.errors.username && (
                        <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
                          {formik.errors.username}*
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-300"
                      >
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type="email"
                          required
                          className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {formik.touched.email && formik.errors.email && (
                        <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
                          {formik.errors.email}*
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="md:flex gap-x-4 justify-between">
                    <div className="w-full">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-300"
                      >
                        Password
                      </label>
                      <div className="mt-2 relative">
                        <input
                          id="password"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type={showPass ? 'text' : 'password'}
                          required
                          className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-200 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {formik.touched.password && formik.errors.password && (
                        <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
                          {formik.errors.password}*
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="confirmPassword"
                          className="block text-sm font-medium leading-6 text-gray-300"
                        >
                          Confirm Password
                        </label>
                      </div>
                      <div className="mt-2 relative">
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type={showPass ? 'text' : 'password'}
                          autoComplete="current-password"
                          required
                          className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        />
                        <span
                          onClick={() => setShowPass(!showPass)}
                          className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-600 cursor-pointer"
                        >
                          {!showPass ? (
                            <FaRegEye
                              className="h-6 w-6 text-gray-600"
                              aria-hidden="true"
                            />
                          ) : (
                            <FaRegEyeSlash
                              className="h-6 w-6 text-gray-600"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </div>
                      {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword && (
                          <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
                            {formik.errors.confirmPassword}*
                          </p>
                        )}
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="referralBy"
                      className="block text-sm font-medium leading-6 text-gray-300"
                    >
                      Referral Code 
                    </label>
                    <div className="mt-2">
                      <input
                        id="referralBy"
                        name="referralBy"
                        required
                        value={referralCode || formik.values.referralBy}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-300 bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                        disabled={!!referralCode}
                      />
                    </div>
                  </div>

                  <div className="flex gap-x-4 justify-center">
                    <button
                      type="submit"
                      className="w-full flex justify-center rounded-md mt-3 bg-gray-700 py-2 px-3 text-sm font-semibold leading-6 text-gray-300 shadow-sm hover:bg-gray-600 "
                      disabled={loading}
                    >
                      {loading ? <Spinner /> : "Sign Up"}
                    </button>
                  </div>
                </form>
                <p className="mt-4 text-center text-gray-700 text-sm ">
               Already a member!{" "}
              <Link
                to="/user/login"
                className="font-semibold leading-6 text-gray-700"
              >
                Login Here
              </Link>
            </p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
            <img
              alt=""
              src="./bot.png"
              className="absolute inset-0 h-full w-full object-cover"             />
          </div>
      </div>

      {error && <ErrorAlert error={error} />}
      {message && <SuccessAlert message={message} />}
    </>
  );
}
