// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Autoplay } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";

// export default function SwiperSlides() {
//   return (
//     <>
//       <div className="lg:mt-[84px] sm:mt-[80px] mt-[80px]">
//         <Swiper
//           navigation={false}
//           modules={[Navigation, Autoplay]}
//           autoplay={{
//             delay: 3000,
//             disableOnInteraction: false,
//           }}
//           className="w-full h-auto"
//         >
//           <SwiperSlide className="flex justify-center items-center">
//             <img
//               src="https://media.licdn.com/dms/image/v2/D4E12AQFvz7PCwE4Abw/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1706882087458?e=2147483647&v=beta&t=VcW8H4vQE2QDtm10rsqUg1nvExZSxXfjK3TmaWMiRtc"
//               alt="Trading"
//               className="w-full h-[600px] object-cover"
//             />{" "}
//             {/* Set height */}
//           </SwiperSlide>
//           <SwiperSlide className="flex justify-center items-center">
//             <img
//               src="https://priceactionltd.com/wp-content/uploads/2020/12/Forex-trading-robots-scaled.jpg"
//               alt="Black Robot"
//               className="w-full h-[600px] object-cover"
//             />{" "}
//             {/* Set height */}
//           </SwiperSlide>
//           <SwiperSlide className="flex justify-center items-center">
            
//             <img
//               src="https://static.vecteezy.com/system/resources/thumbnails/040/895/879/small_2x/ai-generated-back-view-of-a-financial-analyst-day-trader-robot-working-on-computer-with-many-screens-that-shows-real-time-stock-data-photo.jpg"
//               alt="ai-generated"
//               className="w-full h-[600px] object-cover"
//             />{" "}
//             {/* Set height */}
//           </SwiperSlide>
//         </Swiper>
//       </div>
//     </>
//   );
// }
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

export default function SwiperSlides() {
  return (
    <>
      <div className="lg:mt-[84px] sm:mt-[80px] mt-[80px]">
        <Swiper
          navigation={false}
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          className="w-full h-auto"
        >
          <SwiperSlide className="flex justify-center items-center">
            <img
              src="https://media.licdn.com/dms/image/v2/D4E12AQFvz7PCwE4Abw/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1706882087458?e=2147483647&v=beta&t=VcW8H4vQE2QDtm10rsqUg1nvExZSxXfjK3TmaWMiRtc"
              alt="Trading"
              className="w-full h-[300px] md:h-[400px] lg:h-[600px] object-cover"
            />{" "}
            {/* Adjust heights for responsiveness */}
          </SwiperSlide>
          <SwiperSlide className="flex justify-center items-center">
            <img
              src="https://priceactionltd.com/wp-content/uploads/2020/12/Forex-trading-robots-scaled.jpg"
              alt="Black Robot"
              className="w-full h-[300px] md:h-[400px] lg:h-[600px] object-cover"
            />{" "}
            {/* Adjust heights for responsiveness */}
          </SwiperSlide>
          <SwiperSlide className="flex justify-center items-center">
            <img
              src="https://static.vecteezy.com/system/resources/thumbnails/040/895/879/small_2x/ai-generated-back-view-of-a-financial-analyst-day-trader-robot-working-on-computer-with-many-screens-that-shows-real-time-stock-data-photo.jpg"
              alt="AI-Generated"
              className="w-full h-[300px] md:h-[400px] lg:h-[600px] object-cover"
            />{" "}
            {/* Adjust heights for responsiveness */}
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
